<template>
  <el-container>
    <el-header height="3.5rem" class="header-box">
      <div class="flex-def flex-cCenter flex-zCenter" style="width: 100%;height: 100%">
        <div class="flex-def flex-cCenter flex-zBetween" style="height: 100%;width: 100%;max-width: 1200px;">
          <div class="y-pointer" @click="$router.push({name:'home',params:{uni_acid:$store.getters['user/uni_acid']}})">
            <img v-if="$store.state.setting.site_logo" :src="$store.state.setting.site_logo | tomedia" style="height: 2rem;width: 2rem;" alt="logo">
            <span v-else class="y-desc ">logo</span>
          </div>
          <y_layout_head_right_menu></y_layout_head_right_menu>
        </div>
      </div>
    </el-header>
    <el-main class="main-box">
      <y_layout_main><slot></slot></y_layout_main>
    </el-main>
  </el-container>
</template>

<script>
import y_layout_head_right_menu from "@/components/common/y_layout_head_right_menu";
import y_layout_main from "@/components/common/y_layout_main";
export default {
  name: "y-layout",
  components:{
    y_layout_head_right_menu,
    y_layout_main
  },
  data(){
    return{}
  },
  mounted() {
  },
  methods:{
  }
}
</script>
<style>

</style>
<style scoped>
@import "../common/y-layout-base.css";
</style>